import React, { createContext, useEffect, useState } from 'react';
import {
  getAllEntries,
  getHolidayList,
  getMonthEntries,
  getNewLockedDate,
  getProjectList,
} from 'services/api';
import { ENTRYFORM_TAB } from 'utils/constants';
import { dashStringToDate } from 'utils/helpers';

export const CalendarContext = createContext();

const initialSelectedTab = ENTRYFORM_TAB.PROJECT;
const initialCurrentDate = new Date();
const initialSelectedDate = '';
const initialHours = 0;
const initialSelectedProject = '';
const initialSelectedWorkstream = '';
const initialSelectedTask = '';
const initialWorkDescription = '';
const initialIsHalfDay = false;
const initialHalfDayDate = '';
const initialLeaveOption = '';
const initialLeaveFrom = '';
const initialLeaveTo = '';
const initialLeaveReason = '';
const initialProjectEditMode = false;
const intialLeaveEditMode = false;
const initialHolidayList = [];
const initialProjectList = [
  {
    id: '',
    label: 'Choose Project',
    tasks: [],
    workstreams: [{ id: '', label: 'Choose Workstream' }],
  },
];

export const CalendarContextProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
  const [currentDate, setCurrentDate] = useState(initialCurrentDate);
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [hours, setHours] = useState(initialHours);
  const [selectedProject, setSelectedProject] = useState(
    initialSelectedProject
  );
  const [selectedWorkstream, setSelectedWorkstream] = useState(
    initialSelectedWorkstream
  );
  const [selectedTask, setSelectedTask] = useState(initialSelectedTask);
  const [workDescription, setWorkDescription] = useState(
    initialWorkDescription
  );

  // Leave States
  const [isHalfDay, setHalfDay] = useState(initialIsHalfDay);
  const [halfDayDate, setHalfDayDate] = useState(initialHalfDayDate);
  const [leaveOption, setLeaveOption] = useState(initialLeaveOption);
  const [leaveFrom, setLeaveFrom] = useState(initialLeaveFrom);
  const [leaveTo, setLeaveTo] = useState(initialLeaveTo);
  const [leaveReason, setLeaveReason] = useState(initialLeaveReason);

  const [projectEditMode, setProjectEditMode] = useState(
    initialProjectEditMode
  );
  const [leaveEditMode, setLeaveEditMode] = useState(intialLeaveEditMode);
  const [entryId, setEntryId] = useState('');
  
  const [lockDate, setLockDate] = useState(new Date());
  const [localLockDate, setLocalLockDate] = useState(new Date());
  const [holidayList, setHolidayList] = useState(initialHolidayList);
  const [projectList, setProjectList] = useState(initialProjectList);
  const [events, setEvents] = useState([]);

  // Entry Modal State
  const [openEntryModal, setEntryModal] = useState(false);
  const [openProjectModal, setProjectModal] = useState(false)

  const updateEntryModal = (newState) => {
    setEntryModal(newState);
  };

  const updateProjectModal = (newState) => {
    setProjectModal(newState);
  };

  const getAllCalendarEntries = () => {
    getAllEntries()
      .then((res) => {
        if (res && res.data) {
          setEvents(res.data.active_entries);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCalendarLockDate = () => {
    getNewLockedDate()
      .then((res) => {
        if (res && res.data) {
          setLockDate(dashStringToDate(res.data.locked_date));
          setLocalLockDate(dashStringToDate(res.data.local_locked_date));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getMonthCalendarEnrties = (newDate) => {
    const params = {
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    };
    getMonthEntries(params)
      .then((res) => {
        setEvents(res.data.active_entries);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAllProjects = () => {
    getProjectList()
      .then((res) => {
        if (res && res.data) {
          const rawProjectList = res.data.projects;
          const projectList = rawProjectList.filter(project => project.id != "On Leave")
          const updatedProjectList = [
            { id: "", label: "Choose Project" },
            ...projectList.map((project) => ({
              ...project,
              tasks: [
                ...project.tasks.map((task) => ({
                  ...task,
                  label: task.title,
                  value: task.title,
                })),
              ],
              workstreams: [
                { id: "", label: "Choose Workstream" },
                ...project.workstreams.map((workstream) => ({
                  ...workstream,
                  label: workstream.id,
                })),
              ],
              label: project.id,
            })),
          ];
          setProjectList(updatedProjectList);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getHolidayList()
      .then((res) => {
        if (res && res.data) {
          setHolidayList(res.data.holidays);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    getAllCalendarEntries();
    getCalendarLockDate();
    getAllProjects();
  }, []);

  const updateSelectedTab = (newTab) => {
    setSelectedTab(newTab);
  };

  const updateCurrentDate = (newDate) => {
    setCurrentDate(newDate);
  };

  const updateSelectedDate = (newDate) => {
    setSelectedDate(newDate);
  };

  const updateHours = (newHours) => {
    setHours(newHours);
  };

  const updateSelectedProject = (newProject) => {
    setSelectedProject(newProject);
    setSelectedWorkstream(initialSelectedWorkstream);
    setSelectedTask(initialSelectedTask);
  };

  const updateSelectedWorkstream = (newWorkstream) => {
    setSelectedWorkstream(newWorkstream);
  };

  const updateSelectedTask = (newTask) => {
    setSelectedTask(newTask);
  };

  const updateWorkDescription = (newDescription) => {
    setWorkDescription(newDescription);
  };

  const updateIsHalfDay = (newValue) => {
    setHalfDay(newValue);
  };

  const updateHalfDayDate = (newDate) => {
    setHalfDayDate(newDate);
  };

  const updateLeaveOption = (newOption) => {
    setLeaveOption(newOption);
  };

  const updateLeaveFrom = (newLeaveFrom) => {
    setLeaveFrom(newLeaveFrom);
  };

  const updateLeaveTo = (newLeaveTo) => {
    setLeaveTo(newLeaveTo);
  };

  const updateLeaveReason = (newReason) => {
    setLeaveReason(newReason);
  };

  const updateIsEditMode = (newValue) => {
    setProjectEditMode(newValue);
  };

  const resetEntryForm = () => {
    resetProjectEntryForm();
    resetLeaveEntryForm();
  };

  const resetLeaveEntryForm = () => {
    setHalfDay(initialIsHalfDay);
    setHalfDayDate(initialHalfDayDate);
    setLeaveOption(initialLeaveOption);
    setLeaveFrom(initialLeaveFrom);
    setLeaveTo(initialLeaveTo);
    setLeaveReason(initialLeaveReason);
    setLeaveEditMode(false);
  };
  const resetProjectEntryForm = () => {
    updateHours('');
    updateSelectedProject('');
    updateSelectedWorkstream('');
    updateSelectedTask('');
    updateWorkDescription('');
    updateIsEditMode(false);
  };
  const updateProjectEditInfo = (data) => {
    if (data.project === 'On Leave') {
      setHalfDay(data.hours === '4.00');
      setHalfDayDate(data.entry_date);
      setLeaveFrom(data.entry_date);
      setLeaveTo(data.entry_date);
      setLeaveOption(data.workstream);
      setLeaveReason(data.description);
      setSelectedTab(ENTRYFORM_TAB.LEAVE);
      setLeaveEditMode(true);
      resetProjectEntryForm();
    } else {
      const currProject = projectList.filter(
        (project) => project.id === data.project
      )[0];
      const currTasks = currProject.tasks.filter(
        (task) => task.title === data.task_title
      );

      updateHours(data.hours);
      updateSelectedProject(data.project);
      updateSelectedWorkstream(data.workstream);
      updateSelectedTask(currTasks.length > 0 ? currTasks[0] : '');
      updateWorkDescription(data.description);
      setSelectedTab(ENTRYFORM_TAB.PROJECT);
      setProjectEditMode(true);
      resetLeaveEntryForm();
    }
    setEntryId(data.id);
  };

  // Create the context value object
  const contextValue = {
    lockDate,
    localLockDate,
    entryId,
    selectedTab,
    updateSelectedTab,
    currentDate,
    updateCurrentDate,
    selectedDate,
    updateSelectedDate,
    hours,
    updateHours,
    selectedProject,
    updateSelectedProject,
    selectedWorkstream,
    updateSelectedWorkstream,
    selectedTask,
    updateSelectedTask,
    workDescription,
    updateWorkDescription,
    isHalfDay,
    updateIsHalfDay,
    halfDayDate,
    updateHalfDayDate,
    leaveOption,
    updateLeaveOption,
    leaveFrom,
    updateLeaveFrom,
    leaveTo,
    updateLeaveTo,
    leaveReason,
    updateLeaveReason,
    projectEditMode,
    leaveEditMode,
    updateIsEditMode,
    updateProjectEditInfo,
    resetEntryForm,
    resetProjectEntryForm,
    resetLeaveEntryForm,
    holidayList,
    projectList,
    events,
    getAllCalendarEntries,
    getMonthCalendarEnrties,
    getCalendarLockDate,
    openEntryModal,
    updateEntryModal,
    openProjectModal,
    updateProjectModal
  };

  // Provide the context value to the children components
  return (
    <CalendarContext.Provider value={contextValue}>
      {children}
    </CalendarContext.Provider>
  );
};
