import React, { useState, useEffect, useContext } from "react";
import "./Header.css";
import { handleSignOut } from "../../services/firebase";
import { AuthContext } from "contexts/AuthContext";
import {
  getActiveUserData,
  getCompleteUserData,
  getUserDetails,
} from "services/api";
import { NextIcon, PrevIcon } from "../../assets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarContext } from "contexts/CalendarContext";
import ProjectRequestModal from "components/Modal/ProjectRequestModal";

function Header({ showDropdown = true, fetchSuperUser = true }) {
  const { currentUser } = useContext(AuthContext);
  const { currentDate, updateCurrentDate, getMonthCalendarEnrties, openProjectModal,
    updateProjectModal } =
    useContext(CalendarContext);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isStaffUser, setIsStaffUser] = useState(false);
  // const [openProjectReqModal, setProjectReqModal] = useState(false);
  const [userActiveData, setUserActiveData] = useState("");
  const [userCompleteData, setUserCompleteData] = useState("");

  const displayName = currentUser ? currentUser.name || "Username" : "Username";
  const APP_URL = `${process.env.REACT_APP_URL}`;

  const handleLogout = () => {
    handleSignOut();
  };

  useEffect(() => {
    if (fetchSuperUser) {
      getUserDetails()
        .then((res) => {
          if (res && res.data) {
            const userData = res.data.data;
            setIsSuperUser(userData.isSuperadmin);
            setIsStaffUser(userData.isStaff);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [fetchSuperUser]);

  const downloadUserActiveData = () => {
    getActiveUserData()
      .then((res) => {
        if (res && res.data) {
          setUserActiveData(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const downloadUserCompleteData = () => {
    getCompleteUserData()
      .then((res) => {
        if (res && res.data) {
          setUserCompleteData(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    downloadUserActiveData();
    downloadUserCompleteData();
  }, []);

  const handlePrevMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1
    );
    updateCurrentDate(prevMonth);
    getMonthCalendarEnrties(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1
    );
    updateCurrentDate(nextMonth);
    getMonthCalendarEnrties(nextMonth);
  };

  const handleMonthChange = (date) => {
    updateCurrentDate(date);
    getMonthCalendarEnrties(date);
  };

  return (
    <React.Fragment>
      <div className="ts-header">
        <div className="header-row">
          <div className="col-4">
            <div style={{ display: "inline-block" }}>
              <a href="/">
                <img
                  src="/images/ignite.png"
                  width="162"
                  height="62"
                  alt="Ignite"
                />
              </a>
            </div>
            <div className="header">TimeSheets</div>
          </div>
          {currentUser && (
            <div className="col-4 month_name">
              <span className="left_arrow">
                <PrevIcon height="15" onClick={handlePrevMonth} />
                <span className="month_font header-month">
                  <DatePicker
                    selected={currentDate}
                    onChange={handleMonthChange}
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                  />
                </span>

                <NextIcon height="15" onClick={handleNextMonth} />
              </span>
            </div>
          )}
          <div className="col-3 nav-item-container">
            {showDropdown && (
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <button
                    className="nav-link dropdown-toggle"
                    type="button"
                    id="navbarDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {displayName}
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="navbarDropdown"
                    x-placement="bottom-end"
                    style={{
                      position: "absolute",
                      willChange: "transform",
                      top: "0px",
                      left: "0px",
                      transform: "translate3d(143px, 40px, 0px)",
                    }}
                  >
                    {isSuperUser && (
                      <React.Fragment>
                        <a
                          href={`${APP_URL}/admin`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dropdown-item"
                        >
                          Admin Panel
                        </a>
                        <HeaderDivider />
                      </React.Fragment>
                    )}
                    <a
                      className="dropdown-item"
                      onClick={() => updateProjectModal(true)}
                    >
                      Request Project Access
                    </a>

                    <HeaderDivider />
                    <a
                      className="dropdown-item"
                      target="_blank"
                      download={`${displayName}_active_data`}
                      href={
                        "data:text/csv;charset=utf-8" +
                        encodeURI(userActiveData)
                      }
                    >
                      Export My Data (Active)
                    </a>

                    <HeaderDivider />
                    <a
                      className="dropdown-item"
                      target="_blank"
                      download={`${displayName}_complete_data`}
                      href={
                        "data:text/csv;charset=utf-8" +
                        encodeURI(userCompleteData)
                      }
                    >
                      Export My Data (Complete)
                    </a>

                    {isSuperUser && (
                      <React.Fragment>
                        <HeaderDivider />
                        <a
                          className="dropdown-item"
                          href={`${APP_URL}/exportform/csv/alltasks`}
                          target="_blank"
                        >
                          Export Task List
                        </a>
                        <HeaderDivider />
                        <a className="dropdown-item" href="/change-lock">
                          Change Lock
                        </a>

                        <HeaderDivider />
                        <a className="dropdown-item" href="/get-token">
                          Get Token
                        </a>
                      </React.Fragment>
                    )}
                    <HeaderDivider />
                    <a className="dropdown-item" onClick={handleLogout}>
                      Log out
                    </a>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <ProjectRequestModal
        openModal={openProjectModal}
        onClose={() => updateProjectModal(false)}
      />
    </React.Fragment>
  );
}

export default Header;

const HeaderDivider = () => {
  return <div className="dropdown-divider" style={{ border: "none" }}></div>;
};
