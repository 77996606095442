import TSButton from "components/TSButton/TSButton";
import React, { useEffect, useState, useContext } from "react";
import { getAllProjectList, postProjectRequest } from "services/api";
import TSToast from "./TSToast";
import { CalendarContext } from "contexts/CalendarContext";

function ProjectRequestModal({ openModal, onClose }) {
  const { projectList } = useContext(CalendarContext);
  const [allProjectList, setAllProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    getAllProjectData();
  }, []);

  const getAllProjectData = () => {
    const params = {
      userOnly: true,
    };
    getAllProjectList(params)
      .then((res) => {
        if (res && res.data) {
          let tempAllProjects = res.data.projects;
          setAllProjectList(tempAllProjects);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleProjectRequest = () => {
    if (selectedProject) {
      postProjectRequest({ project_id: selectedProject })
        .then((res) => {
          setToastMessage();
          setShowToast(true);
          onClose();
          setTimeout(() => {
            setSelectedProject("");
          }, 4000);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  return (
    <React.Fragment>
      <div
        className={`${openModal ? "show show-modal" : ""} fade`}
        id="ProjectModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ProjectModalLabel"
        aria-hidden={!openModal}
        style={{ display: openModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document" style={{ top: "15%" }}>
          <div
            className="modal-content"
            style={{
              boxShadow: "4px 4px 12px 0px #0000001F",
              border: "1px solid #DEDEDE",
              borderRadius: "12px",
              minHeight: "600px",
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">REQUEST PROJECT ACCESS</h5>
              <button type="button" className="close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{
                padding: "1rem 1rem 1rem 1rem",
                maxHeight: "480px",
                overflow: "auto",
              }}
            >
              <div>
                {allProjectList &&
                  allProjectList.map((project) => {
                    return (
                      <label className="radio-label" key={project.id}>
                        <input
                          type="radio"
                          value={project.id}
                          checked={selectedProject == project.id}
                          onChange={(e) => setSelectedProject(e.target.value)}
                          required
                        />
                        {project.id}
                      </label>
                    );
                  })}
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <TSButton
                label="REQUEST"
                value="Request"
                onClick={handleProjectRequest}
              />
            </div>
          </div>
        </div>
      </div>
      <TSToast
        message={`Request for project "${selectedProject}" sent!`}
        open={showToast}
        isHeader={false}
        onClose={() => setShowToast(false)}
      />
    </React.Fragment>
  );
}

export default ProjectRequestModal;
