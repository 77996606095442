import Modal from "components/Modal/Modal";
import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  deleteEntry,
  getFrequentEntries,
  getUserDetails,
  postAllowEditRequest,
  postLeaveEntry,
  postProjectEntry,
  updateEntry,
  updateLeaveEntry,
} from "services/api";
import { CalendarContext } from "../../contexts/CalendarContext";
import {
  ENTRYFORM_TAB,
  MODAL_MESSAGES,
  PROJECT_TAG_COUNT,
} from "../../utils/constants";
import {
  checkHolidayBetweenDates,
  dashStringToDate,
  dateToDashString,
  getFirst,
} from "../../utils/helpers";
import SearchFilterDropdown from "../SearchFilterDropdown/SearchFilterDropDown";
import TSButton from "../TSButton/TSButton";
import "./EntryForm.css";
import TSToast from "components/Modal/TSToast";
import EntryRequestModal from "components/Modal/EntryRequestModal";

const intialValidation = {
  entryDate: false,
  project: false,
  workstream: false,
  hours: false,
};

const initialErrorMessages = {
  entryDate: "",
  project: "",
  workstream: "",
  hours: "",
};

function EntryForm() {
  const {
    entryId,
    localLockDate,
    selectedTab,
    updateSelectedTab,
    currentDate,
    selectedDate,
    updateSelectedDate,
    hours,
    updateHours,
    selectedProject,
    updateSelectedProject,
    selectedWorkstream,
    updateSelectedWorkstream,
    selectedTask,
    updateSelectedTask,
    workDescription,
    updateWorkDescription,
    isHalfDay,
    updateIsHalfDay,
    halfDayDate,
    updateHalfDayDate,
    leaveFrom,
    updateLeaveFrom,
    leaveOption,
    updateLeaveOption,
    leaveTo,
    updateLeaveTo,
    leaveReason,
    updateLeaveReason,
    projectEditMode,
    leaveEditMode,
    updateIsEditMode,
    projectList,
    resetProjectEntryForm,
    resetLeaveEntryForm,
    getMonthCalendarEnrties,
    getCalendarLockDate,
    holidayList,
    openEntryModal,
    updateEntryModal,
    updateProjectModal,
    events,
  } = useContext(CalendarContext);

  const { currentUser } = useContext(AuthContext);

  const [workstreams, setWorkStream] = useState([{ id: "", label: "Choose" }]);
  const [tasks, setTasks] = useState([]);
  const [projectTags, setProjectTags] = useState([]);
  const [modalStyle, setModalStyle] = useState({});
  const [leaveDays, setLeaveDays] = useState(1);

  // Modal states
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [onSuccess, setOnSuccess] = useState(null);
  const [onReject, setOnReject] = useState(null);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allowOldEntries, setAllowOldEntries] = useState(false);

  const [validationError, setValidationError] = useState(intialValidation);
  const [errorMessages, setErrorMessages] = useState(initialErrorMessages);
  const [openEditRequestModal, setEditRequestModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const eventsPerDay = events.filter(
    (event) => event.entry_date === selectedDate
  );

  let hoursPerDay = eventsPerDay.reduce((total, event) => {
    return total + parseFloat(event.hours);
  }, 0);

  hoursPerDay = parseFloat(hoursPerDay.toFixed(2));

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleValidationError = (field, value) => {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: !value ? true : false,
    }));
  };

  const handleLeaveDays = (event) => {
    const days = event.target.value;
    if (leaveFrom) {
      const startDate = dashStringToDate(leaveFrom);
      const newDate = startDate;
      newDate.setDate(startDate.getDate() + parseInt(days - 1));
      updateLeaveTo(dateToDashString(newDate));
      setLeaveDays(days);
    }
  };

  useEffect(() => {
    setValidationError(intialValidation);
    setLeaveDays(1);
  }, [openEntryModal]);

  useEffect(() => {
    console.log(hoursPerDay);
    getUserDetails()
      .then((res) => {
        if (res && res.data) {
          const userData = res.data.data;
          setAllowOldEntries(userData.allowOldEntries);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedDate]);

  const handleDeleteEntry = (event) => {
    event.preventDefault();
    setModalMessage(MODAL_MESSAGES.DELETE_WARNING);
    setConfirmModal(true);
    setOnReject(() => handleModalClose);
    setOnSuccess(() => deleteProjectEntry);
    setModalOpen(true);
  };

  const deleteProjectEntry = () => {
    setIsLoading(true);
    deleteEntry(entryId)
      .then((res) => {
        resetProjectEntryForm();
        resetLeaveEntryForm();
        getMonthCalendarEnrties(currentDate)();
        getCalendarLockDate();
      })
      .catch((error) => {
        const errorRes = error?.response;
        if (errorRes && errorRes.data) {
          handleMessageWarning(errorRes.data.error);
        }
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
        updateEntryModal(false);
      });
    setModalOpen(false);
    setConfirmModal(false);
  };

  const handleMessageWarning = (message) => {
    setConfirmModal(false);
    setModalMessage(message);
    setOnSuccess(() => handleModalClose);
    setModalOpen(true);
  };

  const handleSaveProjectEntry = (event) => {
    event.preventDefault();

    if (!selectedProject) {
      setModalMessage(MODAL_MESSAGES.PROJECT_WARNING);
    } else if (!selectedWorkstream) {
      setModalMessage(MODAL_MESSAGES.WORKSTREAM_WARNING);
    } else if (!selectedDate) {
      setModalMessage(MODAL_MESSAGES.DATE_WARNING);
    } else if (!hours || parseFloat(hours) > 24) {
      setModalMessage(MODAL_MESSAGES.HOURS_WARNING);
    } else if (!selectedTask && !workDescription) {
      setModalMessage(MODAL_MESSAGES.TASK_WARNING);
    } else {
      const data = {
        current_user: currentUser.userName,
        entry_date: selectedDate,
        project: selectedProject,
        workstream: selectedWorkstream,
        task_title: selectedTask.title ?? "",
        description: workDescription,
        hours: hours,
      };
      setIsLoading(true);
      if (projectEditMode) {
        updateEntry(entryId, data)
          .then((res) => {
            resetProjectEntryForm();
            getMonthCalendarEnrties(currentDate);
            getCalendarLockDate();
          })
          .catch((error) => {
            const errorRes = error?.response;
            if (errorRes && errorRes.data) {
              handleMessageWarning(errorRes.data.error);
            }
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
            updateEntryModal(false);
          });
      } else {
        postProjectEntry(data)
          .then((res) => {
            resetProjectEntryForm();
            getMonthCalendarEnrties(currentDate);
            getCalendarLockDate();
          })
          .catch((error) => {
            const errorRes = error?.response;
            if (errorRes && errorRes.data) {
              handleMessageWarning(errorRes.data.error);
            }
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
            updateEntryModal(false);
          });
      }
      return;
    }
    setConfirmModal(false);
    setOnSuccess(() => handleModalClose);
    setModalOpen(true);
  };

  const handleSaveLeaveEntry = (event) => {
    event.preventDefault();

    if (!leaveOption) {
      setModalMessage(MODAL_MESSAGES.LEAVE_TYPE_WARNING);
    } else if (isHalfDay && !halfDayDate) {
      setModalMessage(MODAL_MESSAGES.DATE_WARNING);
    } else if (!isHalfDay && (!leaveFrom || !leaveTo)) {
      setModalMessage(MODAL_MESSAGES.DATE_WARNING);
    } else if (!leaveReason) {
      setModalMessage(MODAL_MESSAGES.LEAVE_REASON_WARNING);
    } else if (
      !isHalfDay &&
      checkHolidayBetweenDates(holidayList, leaveFrom, leaveTo)
    ) {
      setModalMessage(MODAL_MESSAGES.HOLIDAY_WARNING);
    } else if (
      isHalfDay &&
      checkHolidayBetweenDates(holidayList, halfDayDate, halfDayDate)
    ) {
      setModalMessage(MODAL_MESSAGES.HOLIDAY_WARNING);
    } else {
      var data = {
        reason: leaveOption,
        description: leaveReason,
        hours: isHalfDay ? "4" : "8",
      };
      setIsLoading(true);
      if (leaveEditMode) {
        data = {
          ...data,
          entry_date: isHalfDay ? halfDayDate : leaveFrom,
          current_user: currentUser.userName,
        };
        updateLeaveEntry(entryId, data)
          .then((res) => {
            resetLeaveEntryForm();
            getMonthCalendarEnrties(currentDate);
            getCalendarLockDate();
          })
          .catch((error) => {
            const errorRes = error?.response;
            if (errorRes && errorRes.data) {
              handleMessageWarning(errorRes.data.error);
            }
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
            updateEntryModal(false);
          });
      } else {
        data = {
          ...data,
          start_date: isHalfDay ? halfDayDate : leaveFrom,
          end_date: isHalfDay ? halfDayDate : leaveTo,
        };
        postLeaveEntry(data)
          .then((res) => {
            resetLeaveEntryForm();
            getMonthCalendarEnrties(currentDate);
            getCalendarLockDate();
          })
          .catch((error) => {
            const errorRes = error?.response;
            if (errorRes && errorRes.data) {
              handleMessageWarning(errorRes.data.error);
            }
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
            updateEntryModal(false);
          });
      }
      return;
    }
    setConfirmModal(false);
    setOnSuccess(() => handleModalClose);
    setModalOpen(true);
  };

  useEffect(() => {
    if (ENTRYFORM_TAB.PROJECT === selectedTab && !projectEditMode) {
      const params = {
        date: dateToDashString(currentDate),
        offset: 7,
      };
      getFrequentEntries(params)
        .then((res) => {
          if (res && res.data) {
            let frequent_entries = res.data.frequent_entries;
            frequent_entries = frequent_entries.filter(
              (project) => project.project !== "On Leave"
            );
            setProjectTags(frequent_entries);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedDate]);

  const handleProjectTagClick = (project) => {
    updateSelectedProject(project.project);
    updateSelectedWorkstream(project.workstream);
  };

  useEffect(() => {
    const filterProject = projectList.filter(
      (project) => project.id === selectedProject
    );
    if (filterProject.length > 0) {
      if (filterProject[0].workstreams) {
        setWorkStream(filterProject[0].workstreams);
      } else {
        setWorkStream([{ id: "", label: "Choose Workstream" }]);
      }
      setTasks(filterProject[0].tasks);
    } else {
      setWorkStream([{ id: "", label: "Choose Workstream" }]);
    }
  }, [selectedProject]);

  useEffect(() => {
    updateSelectedDate(dateToDashString(currentDate));
    updateLeaveFrom(dateToDashString(currentDate));
    updateLeaveTo(dateToDashString(currentDate));
    updateHalfDayDate(dateToDashString(currentDate));
    updateLeaveOption("On Leave");
  }, [currentDate]);

  const handleHoursBlur = (field, hours) => {
    let errorMessage = "";
    let value = parseFloat(hours);
    if (isNaN(value)) {
      errorMessage = "";
    } else if (value + hoursPerDay > 24) {
      value = 0;
      errorMessage =
        "Total time spent in a day cannot exceed 24 hours. Please adjust your entry.";
    } else if (value > 8) {
      value = 8;
      errorMessage = "Time spent must be between 0.25 and 8 hours.";
    } else if (value % 0.25 !== 0) {
      value = Math.round(value * 4) / 4; // Round to nearest 0.25
      errorMessage = "Time spent must be in multiples of 0.25 hours.";
    }
    setErrorMessages((prevState) => ({
      ...prevState,
      [field]: errorMessage,
    }));
    setShowAlert(!!errorMessage);
    handleValidationError(field, value);
    updateHours(value);
  };

  const DismissibleAlert = ({ message, onClose }) => {
    return (
      <div
        className="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        {message}
        <button type="button" className="close" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>
    );
  };

  const handleHoursChange = (e) => {
    updateHours(parseFloat(e.target.value));
    handleValidationError("hours", parseFloat(e.target.value));
  };
  const handleDateChange = (e) => {
    updateSelectedDate(e.target.value);
  };

  const handleEditEntryAccess = (event) => {
    event.preventDefault();
    updateEntryModal(false);
    setEditRequestModal(true);
  };

  const handleProjectNotListed = (events) => {
    events.preventDefault();
    updateEntryModal(false);
    updateProjectModal(true)
  }
  return (
    <React.Fragment>
      <div
        className={`${openEntryModal ? "show show-modal" : ""} fade`}
        id="EntryModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="EntryModalLabel"
        aria-hidden={!openEntryModal}
        style={{ display: openEntryModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document" style={{ top: "13%" }}>
          <div
            className="modal-content"
            style={{
              boxShadow: "4px 4px 12px 0px #0000001F",
              border: "1px solid #DEDEDE",
              borderRadius: "12px",
              minHeight: "600px",
            }}
          >
            <div className="modal-header" style={{ borderBottom: "none" }}>
              <nav className="navbar navbar-expand-lg navbar-light margin_bottom_12">
                <div style={{ width: "100%" }}>
                  <ul
                    className="navbar-nav mr-auto"
                    style={{ flexDirection: "row" }}
                  >
                    {currentDate <= new Date() && (
                      <div
                        className={`${
                          ENTRYFORM_TAB.PROJECT === selectedTab ? "active" : ""
                        }_div`}
                        onClick={() => {
                          if (currentDate <= new Date()) {
                            updateSelectedTab(ENTRYFORM_TAB.PROJECT);
                          }
                        }}
                      >
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              currentDate > new Date() ? "disabled" : ""
                            } ${
                              ENTRYFORM_TAB.PROJECT === selectedTab
                                ? "active"
                                : ENTRYFORM_TAB.PROJECT
                            }`}
                          >
                            <span
                              className={`${ENTRYFORM_TAB.PROJECT} ${
                                ENTRYFORM_TAB.PROJECT === selectedTab
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {ENTRYFORM_TAB.PROJECT}
                            </span>
                          </a>
                        </li>
                      </div>
                    )}
                    <div
                      className={`${
                        ENTRYFORM_TAB.LEAVE === selectedTab ? "active" : ""
                      }_div`}
                      onClick={() => updateSelectedTab(ENTRYFORM_TAB.LEAVE)}
                    >
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            ENTRYFORM_TAB.LEAVE === selectedTab
                              ? "active"
                              : ENTRYFORM_TAB.LEAVE
                          }`}
                        >
                          <span
                            className={`${ENTRYFORM_TAB.LEAVE} ${
                              ENTRYFORM_TAB.LEAVE === selectedTab
                                ? "active"
                                : ""
                            }`}
                          >
                            {ENTRYFORM_TAB.LEAVE}
                          </span>
                        </a>
                      </li>
                    </div>
                  </ul>
                </div>
              </nav>

              <button
                type="button"
                className="close"
                onClick={() => {
                  updateEntryModal(false);
                  setShowAlert(false);
                }}
                style={{ position: "absolute", right: "30px" }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "0 1rem 1rem 1rem" }}>
              <div>
                <div style={{ padding: "0 5px" }}>
                  {selectedTab === ENTRYFORM_TAB.PROJECT && (
                    <form>
                      <div className="form-row">
                        <div className="center-flex">
                          {currentDate < localLockDate && !allowOldEntries ? (
                            <div className="alert alert-danger" role="alert">
                              {MODAL_MESSAGES.DATE_ENTRY_WARNING}
                            </div>
                          ) : (
                            showAlert &&
                            errorMessages.hours && (
                              <DismissibleAlert
                                message={errorMessages.hours}
                                onClose={() => setShowAlert(false)}
                              />
                            )
                          )}
                        </div>
                        <div className="col-6 center-flex">
                          <div className="entry-input">
                            <input
                              type="date"
                              id="datePicker"
                              value={selectedDate}
                              onChange={handleDateChange}
                              max={dateToDashString(new Date())}
                            />
                          </div>
                        </div>
                        <div className="col-6 center-flex">
                          <div className="entry-input">
                            <input
                              className={`field-active form-control ${
                                validationError.hours ? "is-invalid" : ""
                              }`}
                              type="number"
                              name="hours"
                              value={hours}
                              placeholder="Time Spent"
                              step="0.25"
                              min="0.25"
                              max="8"
                              onBlur={() => handleHoursBlur("hours", hours)}
                              onChange={handleHoursChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        {projectTags &&
                          projectTags.map((project) => (
                            <div
                              className="project-tag"
                              onClick={() => handleProjectTagClick(project)}
                            >{`${project.project} | ${project.workstream}`}</div>
                          ))}
                      </div>
                      <div className="center-flex">
                        <div className="entry-input select-full-width">
                          <span>
                            <select
                              className={`minimal form-control ${
                                validationError.project ? "is-invalid" : ""
                              } ${
                                selectedProject ? "field-active" : "field-empty"
                              }`}
                              name="Project"
                              required
                              id="id_Project"
                              value={selectedProject}
                              onChange={(e) => {
                                updateSelectedProject(e.target.value);
                                handleValidationError(
                                  "project",
                                  e.target.value
                                );
                              }}
                              onBlur={() =>
                                handleValidationError(
                                  "project",
                                  selectedProject
                                )
                              }
                            >
                              {projectList.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </span>
                        </div>

                      </div>
                      <div onClick={handleProjectNotListed} className="not-listed-link">
                        Not listed?
                      </div>
                      <div className="center-flex">
                        <div className="entry-input select-full-width">
                          <span>
                            <select
                              className={`minimal form-control ${
                                validationError.workstream ? "is-invalid" : ""
                              } ${
                                selectedWorkstream
                                  ? "field-active"
                                  : "field-empty"
                              }`}
                              name="Workstream"
                              required
                              id="id_Workstream"
                              value={selectedWorkstream}
                              onChange={(e) => {
                                updateSelectedWorkstream(e.target.value);
                                handleValidationError(
                                  "workstream",
                                  e.target.value
                                );
                              }}
                              onBlur={() =>
                                handleValidationError(
                                  "workstream",
                                  selectedWorkstream
                                )
                              }
                            >
                              {workstreams &&
                                workstreams.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          </span>
                        </div>
                      </div>
                      <div className="center-flex">
                        <div className="entry-input select-full-width">
                          <SearchFilterDropdown
                            task={selectedTask}
                            onChange={(value) => updateSelectedTask(value)}
                            options={tasks}
                          />
                        </div>
                      </div>
                      <div className="center-flex">
                        <div className="entry-input select-full-width">
                          <textarea
                            name="description"
                            rows="4"
                            placeholder="What did you work on?"
                            type="text"
                            minLength="10"
                            value={workDescription}
                            onChange={(e) =>
                              updateWorkDescription(e.target.value)
                            }
                            className="field-active"
                          ></textarea>
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: "0px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {!projectEditMode &&
                          (currentDate >= localLockDate || allowOldEntries) && (
                            <TSButton
                              label="SAVE"
                              value="save"
                              onClick={handleSaveProjectEntry}
                              disabled={isLoading}
                            />
                          )}
                        {currentDate < localLockDate && !allowOldEntries && (
                          <TSButton
                            label="Request Edit Access"
                            value="save"
                            disabled={isLoading}
                            onClick={handleEditEntryAccess}
                          />
                        )}
                        {projectEditMode &&
                          (currentDate >= localLockDate || allowOldEntries) && (
                            <TSButton
                              label="DELETE"
                              value="delete"
                              secondary={true}
                              disabled={isLoading}
                              onClick={handleDeleteEntry}
                            />
                          )}
                        {projectEditMode &&
                          (currentDate >= localLockDate || allowOldEntries) && (
                            <TSButton
                              label="UPDATE"
                              value="update"
                              disabled={isLoading}
                              onClick={handleSaveProjectEntry}
                            />
                          )}
                      </div>
                    </form>
                  )}
                  {selectedTab === ENTRYFORM_TAB.LEAVE && (
                    <form validate="true">
                      <div>
                        <input
                          type="checkbox"
                          name="check_me_out"
                          id="id_check_me_out"
                          checked={isHalfDay}
                          onChange={() => updateIsHalfDay(!isHalfDay)}
                        />
                        <span className="Half-Day"> Half-Day</span>
                      </div>
                      <br />
                      <div
                        id="leave_type_container"
                        style={{ display: "flex", height: "43px" }}
                      >
                        <label className="Sick-Leave">
                          <input
                            type="radio"
                            name="leavetype"
                            id="leave_type_personal"
                            value="On Leave"
                            checked={leaveOption === "On Leave"}
                            onChange={(e) => updateLeaveOption(e.target.value)}
                            required
                          />
                          &nbsp;Personal
                        </label>
                        &nbsp;&nbsp;
                        <label className="Sick-Leave">
                          <input
                            type="radio"
                            name="leavetype"
                            id="leave_type_sick"
                            value="Sick Leave"
                            checked={leaveOption === "Sick Leave"}
                            onChange={(e) => updateLeaveOption(e.target.value)}
                            required
                            disabled={currentDate > new Date()}
                          />
                          &nbsp;Sick
                        </label>
                      </div>
                      {isHalfDay && (
                        <div className="center-flex">
                          <div style={{ width: "100%" }}>
                            <label className="entry-label">Date</label>
                            <div className="entry-input select-full-width">
                              <input
                                type="date"
                                id="datePicker"
                                value={halfDayDate}
                                onChange={(e) =>
                                  updateHalfDayDate(e.target.value)
                                }
                                disabled={leaveEditMode}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {!isHalfDay && (
                        <React.Fragment>
                          <div
                            className="center-flex col-6"
                            style={{ display: "inline-block", padding: 0 }}
                          >
                            <div>
                              <label className="entry-label">From</label>
                              <div className="entry-input select-full-width">
                                <input
                                  type="date"
                                  id="datePicker"
                                  value={leaveFrom}
                                  max={leaveTo}
                                  onChange={(e) =>
                                    updateLeaveFrom(e.target.value)
                                  }
                                  disabled={leaveEditMode}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className="center-flex col-6"
                            style={{ display: "inline-block" }}
                          >
                            <div>
                              <label className="entry-label">Days</label>
                              <div className="entry-input select-full-width">
                                <input
                                  type="number"
                                  name="days"
                                  value={leaveDays}
                                  placeholder="Days"
                                  step="1"
                                  min="1"
                                  max="365"
                                  required
                                  onChange={handleLeaveDays}
                                  disabled={leaveEditMode}
                                />
                                {/* <input
                                  type="date"
                                  id="datePicker"
                                  value={leaveTo}
                                  min={leaveFrom}
                                  onChange={(e) => updateLeaveTo(e.target.value)}
                                  disabled={leaveEditMode}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )}

                      <div className="center-flex">
                        <div
                          className="entry-input"
                          style={{ marginTop: "10px" }}
                        >
                          <textarea
                            name="description"
                            rows="4"
                            placeholder="Describe reason for leave"
                            type="text"
                            minLength="10"
                            id="id_description"
                            value={leaveReason}
                            onChange={(e) => updateLeaveReason(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "4px",
                          justifyContent: "center",
                        }}
                      >
                        {!leaveEditMode &&
                          (currentDate >= localLockDate || allowOldEntries) && (
                            <TSButton
                              label="SAVE"
                              value="save"
                              disabled={isLoading}
                              onClick={handleSaveLeaveEntry}
                            />
                          )}
                        {currentDate < localLockDate && !allowOldEntries && (
                          <TSButton
                            label="Request Edit Access"
                            value="save"
                            disabled={isLoading}
                            onClick={handleEditEntryAccess}
                          />
                        )}
                        {leaveEditMode &&
                          (currentDate >= localLockDate || allowOldEntries) && (
                            <TSButton
                              label="DELETE"
                              value="delete"
                              secondary={true}
                              disabled={isLoading}
                              onClick={handleDeleteEntry}
                            />
                          )}
                        {leaveEditMode &&
                          (currentDate >= localLockDate || allowOldEntries) && (
                            <TSButton
                              label="UPDATE"
                              value="update"
                              disabled={isLoading}
                              onClick={handleSaveLeaveEntry}
                            />
                          )}
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={modalOpen}
        message={modalMessage}
        isConfirmModal={isConfirmModal}
        onSuccess={onSuccess}
        onReject={onReject}
      />

      <EntryRequestModal
        openModal={openEditRequestModal}
        onClose={() => setEditRequestModal(false)}
      />
    </React.Fragment>
  );
}

export default EntryForm;
